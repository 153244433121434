<template>
  <CustomDialog
    title="Send Form"
    :open.sync="open"
    button1-text="Cancel"
    button2-text="Send"
    :main-btn-disabled="is_disabled"
    @button1="clear_and_close"
    @button2="send_email_message"
  >
    <template v-slot:content>
      <v-row dense class="custom-dialog">
        <v-col cols="12">
          <label class="primary--text subtitle-2">
            To emails * (separated by comma)
          </label>
          <v-text-field
            dense
            v-model.trim="to"
            class="mb-1"
            outlined
            :rules="[multipleEmailRule(), requiredRule()]"
            clearable
            hide-details="auto"
            prepend-inner-icon="mdi-email-plus-outline"
            color="#657186"
          />
        </v-col>
        <v-col cols="12">
          <label class="primary--text subtitle-2">Subject *</label>
          <v-text-field
            required
            dense
            v-model.trim="subject"
            class="mb-1"
            :rules="requiredRules"
            prepend-inner-icon="mdi-mail"
            clearable
            outlined
            hide-details="auto"
            color="#657186"
          />
        </v-col>
        <v-col cols="12">
          <label class="primary--text subtitle-2">Email Body *</label>
          <Editor
            :key="ckey"
            required
            v-model="message"
            min-height="250"
          ></Editor>
        </v-col>
      </v-row>
    </template>
  </CustomDialog>
</template>
<script>
import Editor from '@/common/Editor/Editor.vue'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import request from '@/services/axios_instance'

export default {
  name: 'SendForm',
  components: {
    CustomDialog,
    Editor
  },
  props: {
    value: { type: Boolean, default: false },
    handler: { type: String, default: 'api/forms/send-email-form' },
    item: Object
  },
  data: () => ({
    to: null,
    subject: null,
    message: null,
    sending: false,
    ckey: 0,
    open: false,
    activeItem: null
  }),
  watch: {
    open(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.open = val
    },
    item: {
      handler: function (val) {
        this.activeItem = val
        this.message = `Form Link: ${this.getLink(val)}`
        this.ckey += 1
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    is_disabled() {
      for (let i = 0; i < this.to_emails.length; i++) {
        if (!this.validateEmail(this.to_emails[i].trim())) {
          return true
        }
      }
      return (
        !this.subject ||
        this.to_emails.length === 0 ||
        !this.message ||
        this.message === '<p></p>'
      )
    },
    hasFormNote() {
      return this.item ? true : false
    },
    to_emails() {
      if (!this.to) {
        return []
      }
      return this.to.split(',')
    }
  },
  methods: {
    getLink(item) {
      if (!item) return ''
      const link = `${this.settings.BaseURL}/form/${item.slug}`
      return `<a href="${link}">${link}</a>`
    },

    clear_and_close() {
      this.open = false
      this.activeItem = this.message = null
    },

    send_email_message() {
      this.sending = true
      var payload = {
        to_emails: this.to_emails,
        message: this.message,
        subject: this.subject
      }
      if (this.item) {
        payload.item_id = this.activeItem.id
      }
      request
        .post(this.handler, payload)
        .then(({ data }) => {
          this.appSnackbar('Email sent!')
          this.clear_and_close()
        })
        .finally(() => {
          this.sending = false
        })
    }
  }
}
</script>
