<template>
  <CustomDialog
    ref="dialog"
    title="QR Code"
    button1-text="Cancel"
    @button1="clear_and_close"
  >
    <template #content>
      <v-row no-gutters class="custom-dialog" ref="qrWrapper" v-if="item">
        <v-col cols="12" class="text-center caption">
          {{ item.title | ucwords }}
        </v-col>
        <img
          :src="qr_image"
          alt="Invalid image"
          style="width: 80%; height: 80%; margin: auto"
        />
      </v-row>
    </template>
    <template slot="button2">
      <v-btn
        class="text-none"
        :disabled="downloading"
        :loading="downloading"
        color="primary"
        @click="download"
      >
        Download QR Code
      </v-btn>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import * as htmlToImage from 'html-to-image'
import { toPng } from 'html-to-image'
import qrcode from 'qrcode'
import download from 'downloadjs'

export default {
  name: 'QRDialog',
  components: {
    CustomDialog
  },
  data: () => ({
    item: null,
    qr_image: '',
    opts: {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 0.3,
      margin: 1,
      color: {
        dark: '#223a6b',
        light: '#ffffff'
      }
    },
    downloading: false
  }),
  computed: {
    formLink() {
      if (!this.item) return ''
      return `${window.location.origin}/form/${this.item.slug}`
    }
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.downloading = false
          let _this = this
          qrcode.toDataURL(this.formLink, this.opts, function (err, url) {
            if (err) throw err
            _this.qr_image = url
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    open_dialog(item) {
      if (item) {
        this.item = item
      }
      this.$refs.dialog.open_dialog()
    },

    close_dialog() {
      this.$refs.dialog.close_dialog()
      this.item = null
    },
    clear_and_close() {
      this.close_dialog()
      this.item = null
    },
    download() {
      this.downloading = true
      htmlToImage
        .toPng(this.$refs.qrWrapper)
        .then(function (dataUrl) {
          download(dataUrl, 'qr-code.png')
        })
        .finally(() => {
          this.downloading = false
        })
    }
  }
}
</script>
