var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forms"},[_c('VueTable',{key:_vm.componentKey,attrs:{"items":_vm.items,"headers":_vm.headers,"show-row-actions":true,"loading":_vm.loading,"title":"Forms","min-height":"80vh","no-more-data":_vm.noMoreData,"show-select":false,"icon":"mdi-file-table"},on:{"load-more":_vm.load_more,"delete-selected":function($event){return _vm.open_bulk_delete_dialog($event)}},scopedSlots:_vm._u([{key:"row-slot",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm._f("ucwords")(item.title)))]),_c('td',[_c('Avatar',{attrs:{"user":item.user,"display":item.user.fullname}})],1),_c('td',[_c('v-chip',{attrs:{"small":"","color":item.status.toLowerCase() === 'active' ? 'primary' : 'error',"pill":""}},[_vm._v(" "+_vm._s(_vm._f("ucwords")(item.status))+" ")])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.responses_count))]),_c('td',[_vm._v(_vm._s(_vm._f("format")(item.created_at)))]),_c('Actions',{attrs:{"item":item,"permissions":_vm.$_permissions.get('forms'),"has-edit":_vm.can_edit(item),"has-delete":_vm.can_delete(item)},on:{"delete":function($event){return _vm.open_delete_dialog(item)},"edit":function($event){return _vm.go_to_form_edit(item)},"view":function($event){return _vm.go_to_form_preview(item)}},scopedSlots:_vm._u([{key:"extra",fn:function(){return [(item.status === 'active')?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.open_send_dialog(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-email-send-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Send to email")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.open_qr_dialog(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-qrcode")])],1)]}}],null,true)},[_c('span',[_vm._v("Show QR Code")])]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.go_to_form_responses(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-email-receive-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Responses")])])]},proxy:true}],null,true)})]}},{key:"empty-slot",fn:function(){return [_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.go_to_form_builder}},[_vm._v(" Create New Form ")])]},proxy:true}])},[_c('template',{slot:"header-toolbar"},[_c('v-btn',{staticClass:"text-none px-5",attrs:{"color":"primary","depressed":""},on:{"click":_vm.go_to_form_builder}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Form ")],1)],1)],2),_c('delete-dialog',{attrs:{"open-dialog":_vm.delete_dialog,"title":"Delete Form","text-content":"Are you sure you want to delete this form?"},on:{"update:openDialog":function($event){_vm.delete_dialog=$event},"update:open-dialog":function($event){_vm.delete_dialog=$event},"delete":_vm.delete_form}}),_c('SendFormDialog',{attrs:{"item":_vm.activeForm},model:{value:(_vm.send_form_dialog),callback:function ($$v) {_vm.send_form_dialog=$$v},expression:"send_form_dialog"}}),_c('QRDialog',{ref:"qr_dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }